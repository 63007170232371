import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { css } from "@emotion/core"



const bio = css`
  margin-left: 2px;
  font-family: 'Raleway', sans-serif;
`

const IndexPage = () => (
  <Layout>
    <SEO title="The Web Development, UX Design, Game Design, and Product Design Portfolio of Ezenwa Isiogu." keywords={[`Design`, `Strategic Communications`, `Graphic Design`, `Web Design`, `Mobile`]} />
    <p css={bio}>Designer, based in Michigan</p>
  </Layout>
)

export default IndexPage
