import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { css } from "@emotion/core"

const heading = css`
  font-weight: 800;
  margin-bottom: 20px;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-size: 3rem;
  white-space: nowrap;
  @media (max-width: 1200px) {
    font-size: 2.2rem;
  }
  @media (max-width: 400px) {
    font-size: 1.6rem;
  }
`
const headingLink = css`
  text-decoration: none;
  color: #FFF;
`

const left = css`
  max-width: 40%;
  @media (max-width: 1200px) {
    max-width: 100%;
  }
`

const intro = css`
  display: block;
  clear: right;
`

const Header = () => (
  <header>
    <div css={left}>
      <h1 css={heading}>
        <Link
          to="/"
          css={headingLink}
        >
          <span css={intro}>Ezenwa Isiogu</span>
        </Link>
      </h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
